import { Dispatch } from 'redux'
import {
  CreateLinkActions,
} from '../types'
import {
  UserActions,
} from '../../user/types'
import { plausibleApi } from 'data/api'
import {
  alertError,
  defineNetworkByStablecoinAddress
} from 'helpers'
import { RootState } from 'data/store'
import * as actionCreateLink from '../actions'
import { ethers, utils } from 'ethers'
import { ERC20Contract } from 'abi'
import { maxLimitAmount } from 'configs/application'

const approve = (
  callback?: () => void
) => {
  return async (
    dispatch: Dispatch<CreateLinkActions> & Dispatch<UserActions>,
    getState: () => RootState
  ) => {
    dispatch(actionCreateLink.setLoading(true))
    const {
      user: {
        provider,
        address,
        signer,
        claimLink,
        connectorId,
        chainId,
        balance
      },
      createLink: {
        tokenAmount,
        escrowAddress,
        feeTokenSymbol,
        isDepositWithAuthAvailable
      },
      token: {
        address: tokenAddress
      }
    } = getState()

    try {

      if (balance.eq('0')) {
        dispatch(actionCreateLink.setLoading(false))
        plausibleApi.invokeEvent({
          eventName: 'no_enough_tokens_for_approve',
          data: {
            tokenAddress: String(tokenAddress),
            chainId: String(chainId)
          }
        })
        return alert(`You don’t have ${feeTokenSymbol} to proceed transaction`)
      }

      if (!tokenAddress) {
        return alertError('tokenAddress is not defined')
      }

      if (claimLink && signer) {
        const contractInstance = new ethers.Contract(tokenAddress, ERC20Contract, signer)
        let iface = new utils.Interface(ERC20Contract)
        // const gasPrice = await provider.getGasPrice()
        // const oneGwei = utils.parseUnits('1', 'gwei')
        const amountToApprove = isDepositWithAuthAvailable ? String(claimLink.totalAmount) : maxLimitAmount
        const approveData = iface.encodeFunctionData('approve', [
          escrowAddress, amountToApprove
        ])

        const allowed = await contractInstance.allowance(address, escrowAddress)
        if (allowed.gte(String(tokenAmount))) {
          dispatch(actionCreateLink.setLoading(false))
          callback && callback()
          return
        }

        const transactionParams = {
          to: tokenAddress,
          // gasPrice: gasPrice.add(oneGwei),
          from: address,
          value: 0,
          data: approveData
        }

        plausibleApi.invokeEvent({
          eventName: 'approve_started',
          data: {
            tokenAddress: String(tokenAddress),
            chainId: String(chainId)
          }
        })
        console.log('HERE1')
        if (connectorId === 'walletConnect') {
          signer.sendTransaction(transactionParams)
        } else {
          await signer.sendTransaction(transactionParams)
        }

        const checkTransaction = async function (): Promise<boolean> {
          return new Promise((resolve, reject) => {
            const checkInterval = setInterval(async () => {
              const allowed = await contractInstance.allowance(address, escrowAddress)
              if (allowed.gte(String(tokenAmount))) {
                resolve(true)
                clearInterval(checkInterval)
              }
            }, 1000)
          })
        }

        const finished = await checkTransaction()
        plausibleApi.invokeEvent({
          eventName: 'approve_finished',
          data: {
            tokenAddress: String(tokenAddress),
            chainId: String(chainId)
          }
        })
        dispatch(actionCreateLink.setLoading(false))
        callback && callback()
    }
      
    } catch (e) {
      const err = e as { code: string }
      if (err.code === "ACTION_REJECTED") {
        plausibleApi.invokeEvent({
          eventName: 'approve_rejected',
          data: {
            tokenAddress: String(tokenAddress),
            chainId: String(chainId)
          }
        })
        alertError('You cancelled the link creation. Please try again')
      } else {
        alertError('Some error occured, please check console for more information')
      }
      console.error({ err })

      dispatch(actionCreateLink.setLoading(false))
    }
  }
}

export default approve

