import { Dispatch } from 'redux'
import * as actions from '../actions'
import { UserActions } from '../types'
import { CreateLinkActions } from '../../create-link/types'
import { LinkdropSDK } from 'linkdrop-sdk'
import randomBytes from 'randombytes'
import { defineJSONRpcUrl } from 'helpers'
import { ethers } from 'ethers'
import switchNetwork from './switch-network'
import { providers } from 'ethers'

import { IAppDispatch, RootState } from 'data/store'


const { REACT_APP_INFURA_ID, REACT_APP_ZUPLO_API_KEY } = process.env

const initialize = (
  connector: any,
  signer: any,
  userChainId: number,
  chainIdToBeConnected?: string,
  userAddress?: string | null
) => {
  // @ts-ignore
  return async (
    dispatch: Dispatch<UserActions> & Dispatch<CreateLinkActions> & IAppDispatch,
    getState: () => RootState
  ) => {
    const { user: { sdk } } = getState()

    console.log({ connector })

    if (userAddress && connector) {
      dispatch(actions.setLoading(true))


      dispatch(actions.setHasConnector(true))
      dispatch(actions.setAddress(userAddress))
      if (connector.id) {
        dispatch(actions.setConnectorId(connector.id))
      }
 
      const jsonRpcUrl = defineJSONRpcUrl({
        chainId: Number(userChainId),
        infuraPk: REACT_APP_INFURA_ID as string
      })
      const jsonRpcProvider = new ethers.providers.JsonRpcProvider(jsonRpcUrl)

      if (connector.getProvider) {
        const provider = await connector.getProvider()

        dispatch(actions.setUserProvider(provider))
      }

      if (jsonRpcProvider.getBalance) {
        const tokenAmount = await jsonRpcProvider.getBalance(userAddress)
        dispatch(actions.setBalance(tokenAmount))
      }

      if (chainIdToBeConnected && (userChainId !== Number(chainIdToBeConnected))) {
        dispatch(switchNetwork(Number(chainIdToBeConnected), () => window.location.reload()))
      } else {
        dispatch(actions.setChainId(userChainId))
        dispatch(actions.setProvider(jsonRpcProvider))
        dispatch(actions.setSigner(signer))


        if (!sdk) {
          const getRandomBytes = (length: number) => new Uint8Array(randomBytes(length)) 
          const sdk = new LinkdropSDK({
            apiKey: REACT_APP_ZUPLO_API_KEY as string,
            baseUrl: window.location.origin,
            getRandomBytes
          })
          dispatch(actions.setSDK(sdk)) 
        }
        
        dispatch(actions.setInitialized(true))
        dispatch(actions.setLoading(false))

      }
    }


  }
}


export default initialize