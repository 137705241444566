import { action } from 'typesafe-actions'
import { Constants } from './constants'
import { LinkdropSDK, ClaimLink, ClaimLinkV2, ClaimLinkV3_11 } from 'linkdrop-sdk'
import { TERC20Token, TWalletName } from 'types'
import { BigNumber } from 'ethers'

export function setAddress(address: string) {
  return action(
    Constants.USER_SET_ADDRESS,
    {
      address
    }
  )
}

export function setBalance(balance: BigNumber) {
  return action(Constants.USER_SET_BALANCE, {
    balance
  })
}

export function setLoading(loading: boolean) {
  return action(
    Constants.USER_SET_LOADING,
    {
      loading
    }
  )
}

export function setConnectorId(connectorId: string) {
  return action(
    Constants.USER_SET_CONNECTOR_ID,
    {
      connectorId
    }
  )
}

export function setERC20Tokens(erc20Tokens: TERC20Token[]) {
  return action(
    Constants.USER_SET_ERC20_TOKENS,
    {
      erc20Tokens
    }
  )
}

export function setClaimLink(claimLink: ClaimLink | ClaimLinkV2 | ClaimLinkV3_11) {
  return action(
    Constants.USER_SET_CLAIM_LINK,
    {
      claimLink
    }
  )
}

export function setEthPrice (price: string) {
  return action(Constants.USER_SET_ETH_PRICE, { ethPrice: price })
}

export function setWalletApp (walletApp: TWalletName) {
  return action(Constants.USER_SET_WALLET_APP, { walletApp })
}


export function setMaticPrice (price: string) {
  return action(Constants.USER_SET_MATIC_PRICE, { maticPrice: price })
}

export function setSigner(signer: any) {
  return action(
    Constants.USER_SET_SIGNER,
    {
      signer
    }
  )
}

export function setProvider(provider: any) {
  return action(
    Constants.USER_SET_PROVIDER,
    {
      provider
    }
  )
}

export function setChainId(chainId: number) {
  return action(
    Constants.USER_SET_CHAIN_ID,
    {
      chainId
    }
  )
}

export function setSDK(sdk: LinkdropSDK) {
  return action(
    Constants.USER_SET_SDK,
    {
      sdk
    }
  )
}

export function setInitialized(initialized: boolean) {
  return action(
    Constants.USER_SET_INITIALIZED,
    {
      initialized
    }
  )
}

export function setHasConnector(hasConnector: boolean) {
  return action(
    Constants.USER_SET_HAS_CONNECTOR,
    {
      hasConnector
    }
  )
}

export function setUserProvider(userProvider: any) {
  return action(
    Constants.USER_SET_USER_PROVIDER,
    {
      userProvider
    }
  )
}


export function setEmail (email: string) {
  return action(
    Constants.USER_SET_EMAIL,
    {
      email
    }
  )
}

